<template>
  <div id="myConfirmModal">
    <a-modal v-model="visible" title="Confirm" @ok="handleOk" @cancel="handleCancel">
      <p>Are you sure you want to continue?</p>
    </a-modal>
  </div>
</template>

<script>

import { bus } from "@/main";

export default {
  name: "confirm-modal-component",
  props: {
    actionName: {
      type: String
    }
  },

  data() {
    return {
        visible: false,
    };
  },

  methods: {

      showModal() {
          this.visible = true;
      },
    closeModal()
    {
        this.visible = false;
    },
    handleCancel() {
        bus.$emit("confirmAction", "");
        this.$store.dispatch("COMMIT_SET_ACTION", "")
        this.closeModal();
    },
    handleOk() {
      window.console.log('from modal action: '+this.actionName );
      bus.$emit("confirmAction", this.actionName);
      this.closeModal();
    }
  },
  mounted() {

  },
  watch: {
    actionName() {
      console.log("action Name Set to: " + this.actionName);
      if (this.actionName !== null && this.actionName.length > 0) {
        this.showModal();
      }
    }
  }
};
</script>
