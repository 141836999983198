/**
 * Created by Terrence on 2021/06/16.
 */
import http from "../../http-common";

export default{
    state: {
        token: null,
        user: null,
        roles: null,
        selected_role: null,
        account: null,
        trigger: false,
        type: null,
        role_type: null
    },
    mutations: {
        SET_AUTH_TOKEN: (state, _value) => {
            state.token = _value;
        },
        SET_AUTH_USER: (state, _value) => {
            state.user = _value;
        },
        SET_AUTH_ROLES: (state, _value) => {
            state.roles = _value;
        },
        SET_AUTH_ROLE_TYPE: (state, _value) => {
            state.role_type = _value;
        },
        SET_AUTH_TYPE: (state, _value) => {
            state.type = _value;
        },
        SET_AUTH_SELECTED_ROLE: (state, _value) => {
            state.selected_role = _value;
        },
        SET_AUTH_ACCOUNT:  (state, _value) => {
            state.account = _value;
        },
        SET_TRIGGER_AUTH: (state, _value) =>
        {
            state.trigger = _value;
        },
    },
    actions: {
        COMMIT_TRIGGER_AUTH:({commit}) => {
            commit("SET_TRIGGER_AUTH", true)
        },
        COMMIT_CLOSE_AUTH:({commit}) => {
            commit("SET_TRIGGER_AUTH", false)
        },
        COMMIT_SET_AUTH_USER:({commit},_value)=>
        {
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;
            if(_sessionStorage !== null)
                _sessionStorage.setItem('user', JSON.stringify(_value))
            commit('SET_AUTH_USER',_value);
        },
        COMMIT_SET_AUTH_SELECTED_ROLE:({commit},_value)=>
        {
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;
            if(_sessionStorage !== null)
                _sessionStorage.setItem('selected_role', JSON.stringify(_value))
            commit('SET_AUTH_SELECTED_ROLE',_value);
        },
        COMMIT_SET_AUTH_ROLES:({commit},_value)=>
        {
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;
            if(_sessionStorage !== null)
                _sessionStorage.setItem('roles', JSON.stringify(_value))
            commit('SET_AUTH_ROLES',_value);
        },
        COMMIT_SESSION_REFRESH_AUTH : ({commit}) => {
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
            {
                _sessionStorage = window.sessionStorage;
            }
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
            {
                _sessionStorage = sessionStorage;
            }
            if(_sessionStorage !== null)
            {
                let _user = JSON.parse(_sessionStorage.getItem('user'))
                let _token = _sessionStorage.getItem('token')
                let _auth_type = _sessionStorage.getItem('auth_type')
                let _role_type = _sessionStorage.getItem('role_type')
                if(typeof _user !== "undefined"&& _user !== null && _user.token === _token)
                {
                    http.defaults.headers.common['Authorization'] ="Bearer "+ _user.token;
                    if(_auth_type === "employee")
                    {
                        http.defaults.headers.common['x-api-user'] = null;
                        http.defaults.headers.common['x-api-employee'] = _user.key;
                        http.defaults.headers.common['x-api-customer'] = null;
                    }
                    else if(_auth_type === "customer")
                    {
                        http.defaults.headers.common['x-api-user'] = null;
                        http.defaults.headers.common['x-api-employee'] = null;
                        http.defaults.headers.common['x-api-customer'] = _user.key;
                    }
                    else
                    {
                        http.defaults.headers.common['x-api-user'] = _user.key;
                        http.defaults.headers.common['x-api-employee'] = null;
                        http.defaults.headers.common['x-api-customer'] = null;
                    }


                    commit('SET_AUTH_TOKEN', _token)
                    commit('SET_AUTH_USER', _user)
                    commit('SET_AUTH_TYPE', _auth_type)
                    commit('SET_AUTH_ROLE_TYPE', _role_type)
                    commit('SET_AUTH_ROLES', JSON.parse(_sessionStorage.getItem('roles')))
                    commit('SET_AUTH_SELECTED_ROLE', JSON.parse(_sessionStorage.getItem('selected_role')))
                }

            }
        },
        COMMIT_LOGOUT:({commit})=>
        {
            window.console.log("STORE LOGOUT");
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;
            if(_sessionStorage !== null)
            {
                _sessionStorage.setItem('roles', null)
                _sessionStorage.setItem('role_type', null)
                _sessionStorage.setItem('auth_type', null)
                _sessionStorage.setItem('selected_role', null)
                _sessionStorage.setItem('user', null)
                _sessionStorage.setItem('token', null)
            }

            commit('SET_AUTH_ROLES',null);
            commit('SET_AUTH_ROLE_TYPE',null);
            commit('SET_AUTH_USER', null)
            commit('SET_AUTH_TYPE', null)
            commit('SET_AUTH_TOKEN', null)
            commit('SET_AUTH_SELECTED_ROLE', null)
        },
        COMMIT_SESSION_REFRESH_AUTH_USER : ({commit}) => {
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
            {
                _sessionStorage = window.sessionStorage;
            }
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
            {
                _sessionStorage = sessionStorage;
            }
            if(_sessionStorage !== null)
            {
                commit('SET_AUTH_USER', JSON.parse(_sessionStorage.getItem('user')))
                commit('SET_AUTH_TOKEN', _sessionStorage.getItem('token'))
            }
        },
        COMMIT_SESSION_REFRESH_AUTH_ROLE : ({commit}) => {
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
            {
                _sessionStorage = window.sessionStorage;
            }
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
            {
                _sessionStorage = sessionStorage;
            }
            if(_sessionStorage !== null)
            {
                commit('SET_AUTH_ROLES', JSON.parse(_sessionStorage.getItem('roles')))
                commit('SET_AUTH_SELECTED_ROLE', JSON.parse(_sessionStorage.getItem('selected_role')))
            }
        },
        REQUEST_AUTH_REQUEST_PASSWORD_RESET: ({commit,dispatch}, _formData) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/user/password/code',
                    method: "POST",
                    data: _formData,
                    timeout: 30000
                })
                    .then(resp => {
                        commit("STOP_PROCESSING", { root: true });


                        resolve(resp);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        REQUEST_AUTH_PASSWORD_RESET: ({commit,dispatch}, _formData) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/user/password/reset',
                    method: "POST",
                    data: _formData,
                    timeout: 30000
                })
                    .then(resp => {
                        commit("STOP_PROCESSING", { root: true });
                        resolve(resp);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        REQUEST_AUTH_LOGIN: ({commit, dispatch, reject}, user) => {
            return new Promise((resolve) => { // The Promise used for router redirect in login
                commit('AUTH_REQUEST');
                commit('START_PROCESSING');
                http({url: '/login', data: user, method: 'POST', timeout: 30000 })
                    .then(_response =>
                    {
                        window.console.log("LOGIN RESPONSE:", JSON.stringify(_response))
                        commit('STOP_PROCESSING');
                        if(parseInt(_response.data.error_code)>0)
                        {
                            reject(_response)
                            return false
                        }

                        let _data = _response.data.data
                        let _token = _data.token
                        let _key = _data.key
                        let _roles = _data.roles.data
                        let _selected_role = _data.roles.data[0]
                        let _auth_type = "admin"
                        let _role_type = null
                        if(typeof _selected_role.type !== "undefined" && _selected_role.type !== null && typeof _selected_role.type.data !== "undefined" && _selected_role.type.data !== null)
                            _role_type = _selected_role.type.data.alias

                        http.defaults.headers.common['Authorization'] ="Bearer "+ _token;
                        http.defaults.headers.common['x-api-user'] = _key;
                        commit("SET_AUTH_TOKEN", _token)
                        commit('SET_AUTH_USER', _data)
                        commit('SET_AUTH_ROLES', _roles)
                        commit('SET_AUTH_TYPE', _auth_type)
                        commit('SET_AUTH_SELECTED_ROLE', _selected_role)
                        commit('SET_AUTH_ROLE_TYPE', _role_type)

                        let _sessionStorage = null;
                        if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                            _sessionStorage = window.sessionStorage;
                        else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                            _sessionStorage = sessionStorage;
                        if(typeof _sessionStorage !== "undefined" && _sessionStorage !== null)
                        {
                            _sessionStorage.setItem('token', _token) ;
                            _sessionStorage.setItem('auth_type', _auth_type) ;
                            _sessionStorage.setItem('role_type', _role_type) ;
                            _sessionStorage.setItem('user', JSON.stringify(_data)) ;
                            _sessionStorage.setItem('roles', JSON.stringify(_roles)) ;
                            _sessionStorage.setItem('selected_role', JSON.stringify(_selected_role)) ;
                        }
                        resolve(_response.data.data)
                    })
                    .catch(_response =>
                    {
                        commit('STOP_PROCESSING');
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    })
            })
        },
        REQUEST_AUTH_VERIFY: ({commit, dispatch, reject}, user) => {
            return new Promise((resolve) => { // The Promise used for router redirect in login
                commit('AUTH_REQUEST');
                commit('START_PROCESSING');
                http({url: '/auth/check', data: user, method: 'POST', timeout: 30000, headers:{
                    'x-api-user': _user_ref,
                    'x-api-gateway': process.env.VUE_APP_API_KEY,
                }, })
                    .then(_response =>
                    {
                        window.console.log("LOGIN RESPONSE:", JSON.stringify(_response))
                        commit('STOP_PROCESSING');
                        if(parseInt(_response.data.error_code)>0 || _response.data.false)
                        {
                            reject(_response)
                            return false
                        }
                        resolve(_response)
                    })
                    .catch(_response =>
                    {
                        commit('STOP_PROCESSING');
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    })
            })
        },
        REQUEST_AUTH_CHANGE_PASSWORD: ({ commit,dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/users/password/change',
                    method: "POST",
                    data: _formData,
                    timeout: 30000
                })
                    .then(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        REQUEST_GET_AUTH_USER: ({ commit,dispatch }, _user_ref) => {
            commit("START_PROCESSING", { root: true });
            return new Promise(resolve => {
                http({
                    url: '/accounts/'+_user_ref,
                    method: "GET",
                    timeout: 30000,
                    headers:{
                        'x-api-user': _user_ref,
                        'x-api-gateway': process.env.VUE_APP_API_KEY,
                    },
                })
                    .then(_response => {
                        window.console.log("Response GET AUTH USER: ", _response);
                        commit('SET_AUTH_USER', _response.data.data);
                        commit("STOP_PROCESSING", { root: true });
                        resolve(_response);
                    })
                    .catch(_response => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },

        /*AUTH Employee*/
        REQUEST_AUTH_EMPLOYEE_LOGIN: ({commit, dispatch, reject}, user) => {
            return new Promise((resolve) => { // The Promise used for router redirect in login
                commit('AUTH_REQUEST');
                commit('START_PROCESSING');
                http({url: '/staff/login', data: user, method: 'POST', timeout: 30000 })
                    .then(_response =>
                    {
                        window.console.log("LOGIN RESPONSE:", JSON.stringify(_response))
                        commit('STOP_PROCESSING');
                        if(parseInt(_response.data.error_code)>0)
                        {
                            reject(_response)
                            return false
                        }

                        let _data = _response.data.data
                        let _token = _data.token
                        let _key = _data.key
                        let _roles = [_data.role.data]
                        let _selected_role = _data.role.data
                        let _auth_type = "employee"
                        let _role_type = null
                        if(typeof _selected_role.type !== "undefined" && _selected_role.type !== null && typeof _selected_role.type.data !== "undefined" && _selected_role.type.data !== null)
                            _role_type = _selected_role.type.data.alias

                        http.defaults.headers.common['Authorization'] ="Bearer "+ _token;
                        http.defaults.headers.common['x-api-employee'] = _key;
                        commit("SET_AUTH_TOKEN", _token)
                        commit('SET_AUTH_USER', _data)
                        commit('SET_AUTH_ROLES', _roles)
                        commit('SET_AUTH_TYPE', _auth_type)
                        commit('SET_AUTH_SELECTED_ROLE', _selected_role)
                        commit('SET_AUTH_ROLE_TYPE', _role_type)

                        let _sessionStorage = null;
                        if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                            _sessionStorage = window.sessionStorage;
                        else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                            _sessionStorage = sessionStorage;
                        if(typeof _sessionStorage !== "undefined" && _sessionStorage !== null)
                        {
                            _sessionStorage.setItem('token', _token) ;
                            _sessionStorage.setItem('auth_type', _auth_type) ;
                            _sessionStorage.setItem('role_type', _role_type) ;
                            _sessionStorage.setItem('user', JSON.stringify(_data)) ;
                            _sessionStorage.setItem('roles', JSON.stringify(_roles)) ;
                            _sessionStorage.setItem('selected_role', JSON.stringify(_selected_role)) ;
                        }
                        resolve(_response.data.data)
                    })
                    .catch(_response =>
                    {
                        commit('STOP_PROCESSING');
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    })
            })
        },
    }
};