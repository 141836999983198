/**
 * Created by fulle on 2023/02/07.
 */
import http from "@/http-common";
import CUSTOM_CONSTANTS from "@/constants";
import { isNumeric } from "@/app/helpers"


export default {
  state: {
      selected: null,
      list: {
          all: null,
          paginated: null,
          blocked: null
      },
      pagination: null,
      links: null

  },
  mutations: {
      SET_EMPLOYEE_LIST_ALL: (state, _value) => {
          state.list.all = _value;
      },
      SET_SELECTED_EMPLOYEE: (state, _value) => {
          state.selected = _value;
      },
      SET_EMPLOYEE_LIST_PAGINATED: (state, _value) => {
          state.list.paginated = _value;
      },
      UPDATE_EMPLOYEE_LIST_PAGINATED: (state, _value) => {
          Array.prototype.push.apply(state.list.paginated,_value)
          let _arr = state.list.paginated
          state.list.paginated = _arr.filter((value, index) => {
              const _value = JSON.stringify(value);
              return index === _arr.findIndex(obj => {
                      return JSON.stringify(obj) === _value;
                  });
          });
      },
      SET_EMPLOYEE_LIST_BLOCKED: (state, _value) => {
          state.list.blocked = _value;
      },
      UPDATE_EMPLOYEE_LIST_BLOCKED: (state, _value) => {
          Array.prototype.push.apply(state.list.blocked,_value)
          let _arr = state.list.blocked
          state.list.blocked = _arr.filter((value, index) => {
              const _value = JSON.stringify(value);
              return index === _arr.findIndex(obj => {
                      return JSON.stringify(obj) === _value;
                  });
          });
      },
      SET_EMPLOYEE_PAGINATION: (state, _value) => {
          state.pagination = _value;
      },
      SET_EMPLOYEE_PAGINATION_LINKS: (state, _value) => {
          state.links = _value;
      },
  },
  actions: {
      COMMIT_SET_SELECTED_EMPLOYEE: ({ commit}, _value) => {
          commit("SET_SELECTED_EMPLOYEE", _value);
      },
      COMMIT_SET_EMPLOYEE_LIST_PAGINATED: ({ commit}, _value) => {
          commit("SET_EMPLOYEE_LIST_PAGINATED", _value);
      },
      COMMIT_SET_EMPLOYEE_LIST_BLOCKED: ({ commit}, _value) => {
          commit("SET_EMPLOYEE_LIST_BLOCKED", _value);
      },
      REQUEST_EMPLOYEE_LIST_PAGINATED: ({ commit,dispatch }, _formData) => {
          commit("START_PROCESSING", { root: true });
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          return new Promise(resolve => {
              http({
                  url: '/employee/list',
                  method: "POST",
                  timeout: 30000,
                  data: _formData
              })
                  .then(_response => {
                      window.console.log("Response EMPLOYEE PAGINATED LIST: ", _response);
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      let _page = 1
                      if(typeof _formData !== "undefined" && _formData !== null)
                      {
                          if(typeof _formData.page !== "undefined" && _formData.page !== null && isNumeric(_formData.page)&&parseInt(_formData.page)>0)
                              _page = parseInt(_formData.page)
                      }
                      if(_page <= 1)
                          commit('SET_EMPLOYEE_LIST_PAGINATED', _response.data.data);
                      else
                          commit('UPDATE_EMPLOYEE_LIST_PAGINATED', _response.data.data);
                      commit('SET_EMPLOYEE_PAGINATION', _response.data.pagination);
                      commit('SET_EMPLOYEE_PAGINATION_LINKS', _response.data.links);
                      commit("STOP_PROCESSING", { root: true });
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_EMPLOYEE_LIST_BLOCKED: ({ commit,dispatch }, _formData) => {
          commit("START_PROCESSING", { root: true });
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          return new Promise(resolve => {
              http({
                  url: '/employee/list/inactive',
                  method: "POST",
                  timeout: 30000,
                  data: _formData
              })
                  .then(_response => {
                      window.console.log("Response EMPLOYEE BLOCKED PAGINATED LIST: ", _response);
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      let _page = 1
                      if(typeof _formData !== "undefined" && _formData !== null)
                      {
                          if(typeof _formData.page !== "undefined" && _formData.page !== null && isNumeric(_formData.page)&&parseInt(_formData.page)>0)
                              _page = parseInt(_formData.page)
                      }
                      if(_page <= 1)
                          commit('SET_EMPLOYEE_LIST_BLOCKED', _response.data.data);
                      else
                          commit('UPDATE_EMPLOYEE_LIST_BLOCKED', _response.data.data);
                      commit('SET_EMPLOYEE_PAGINATION', _response.data.pagination);
                      commit('SET_EMPLOYEE_PAGINATION_LINKS', _response.data.links);
                      commit("STOP_PROCESSING", { root: true });
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_EMPLOYEE_LIST_SELECT: ({ commit,dispatch }, _formData) => {
          commit("START_PROCESSING", { root: true });
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          return new Promise(resolve => {
              http({
                  url: '/employee/select',
                  method: "POST",
                  timeout: 30000,
                  data: _formData
              })
                  .then(_response => {
                      window.console.log("Response EMPLOYEE PAGINATED LIST: ", _response);
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      commit('SET_EMPLOYEE_LIST_ALL', _response.data.data);
                      commit("STOP_PROCESSING", { root: true });
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_EMPLOYEE_SAVE: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/employee/save',
                  method: "POST",
                  timeout: 30000,
                  data: _formData,
              })
                  .then(_response => {
                      window.console.log("Response EMPLOYEE STORE: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_EMPLOYEE_ADD_MANAGER: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/employee/manager/update',
                  method: "POST",
                  timeout: 30000,
                  data: _formData,
              })
                  .then(_response => {
                      window.console.log("Response EMPLOYEE MANAGER UPDATE: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }

                      commit('SET_SELECTED_EMPLOYEE', _response.data.data);
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_EMPLOYEE_UPDATE: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/employee/update',
                  method: "POST",
                  timeout: 30000,
                  data: _formData,
              })
                  .then(_response => {
                      window.console.log("Response EMPLOYEE MANAGER UPDATE: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }

                      commit('SET_SELECTED_EMPLOYEE', _response.data.data);
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_EMPLOYEE_UPDATE_ROLE: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/employee/role/update',
                  method: "POST",
                  timeout: 30000,
                  data: _formData,
              })
                  .then(_response => {
                      window.console.log("Response EMPLOYEE ROLE UPDATE: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }

                      commit('SET_SELECTED_EMPLOYEE', _response.data.data);
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_EMPLOYEE_VIEW: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/employee/view',
                  method: "POST",
                  timeout: 30000,
                  data: _formData
              })
                  .then(_response => {
                      window.console.log("Response EMPLOYEE VIEW: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      commit("SET_SELECTED_EMPLOYEE", _response.data.data)
                      resolve(_response.data.data);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_EMPLOYEE_ACTIVATE: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/employee/activate',
                  method: "POST",
                  timeout: 30000,
                  data: _formData
              })
                  .then(_response => {
                      window.console.log("Response EMPLOYEE VIEW: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      commit("SET_SELECTED_EMPLOYEE", _response.data.data)
                      resolve(_response.data.data);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_EMPLOYEE_DEACTIVATE: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/employee/deactivate',
                  method: "POST",
                  timeout: 30000,
                  data: _formData
              })
                  .then(_response => {
                      window.console.log("Response EMPLOYEE VIEW: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      commit("SET_SELECTED_EMPLOYEE", _response.data.data)
                      resolve(_response.data.data);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_EMPLOYEE_CHANGE_PASSWORD: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/employee/password/change',
                  method: "POST",
                  timeout: 30000,
                  data: _formData
              })
                  .then(_response => {
                      window.console.log("Response EMPLOYEE VIEW: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      resolve(_response.data.data);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_EMPLOYEE_SEND_PASSWORD_RESET_EMAIL: ({ commit,dispatch, reject }, _formData) => {
          window.console.log("formData", _formData)
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/employee/password/code',
                  method: "POST",
                  timeout: 30000,
                  data: _formData
              })
                  .then(_response => {
                      window.console.log("Response EMPLOYEE VIEW: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      resolve(_response.data.data);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_EMPLOYEE_SEND_PASSWORD_RESET_EMAIL_ADMIN: ({ commit,dispatch, reject }, _formData) => {
          window.console.log("formData", _formData)
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/employee/password/code/admin',
                  method: "POST",
                  timeout: 30000,
                  data: _formData
              })
                  .then(_response => {
                      window.console.log("Response EMPLOYEE VIEW: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      resolve(_response.data.data);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_EMPLOYEE_RESET_PASSWORD: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/employee/password/reset',
                  method: "POST",
                  timeout: 30000,
                  data: _formData
              })
                  .then(_response => {
                      window.console.log("Response PASSWORD RESET: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      resolve(_response.data.data);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_EMPLOYEE_UPDATE_LOGO: ({ commit, dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });

          return new Promise((resolve) => {
              http({
                  url: '/employee/upload',
                  method: "POST",
                  timeout: 30000,
                  data: _formData,
                  headers: {
                      'Content-Type': 'multipart/form-data',
                      'enctype': 'multipart/form-data',
                  },
              })
                  .then((_response) => {
                      window.console.log("Response EMPLOYEE UPLOAD LOGO: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      commit("SET_SELECTED_EMPLOYEE", _response.data.data)
                      resolve(_response.data.data);
                  })
                  .catch((_response) => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },

  },
};
