/**
 * Created by fulle on 2023/03/01.
 */
import moment from "moment"

const isValidDate = (value) => {
    if(typeof value !== "undefined" && value !== null && value.length > 0)
        return moment(value).isValid()

    return true
}
const isValidDateFormat = (value, _format) => {
    if(typeof value !== "undefined" && value !== null && value.length > 0)
        return moment(value, _format).isValid()

    return true
}

const isNumeric = (_value) => {
    return !isNaN(parseFloat(_value)) && isFinite(_value);
}

const formatFileSize = (bytes, decimalPoints) => {
    if(!isNumeric(bytes)||bytes<0)
        return "Undefined"

    if(bytes == 0) return "0 Bytes"
    let k = 1000,
        dm = decimalPoints || 2,
        sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
        i = Math.floor(Math.log(bytes)/Math.log(k))

    return parseFloat((bytes/Math.pow(k,i)).toFixed(dm)) + " "+ sizes[i]
}

const itemExists = (_value) => {
    return typeof _value !== "undefined" && _value !== null;
};
const itemListExists = (_value) => {
    return typeof _value !== "undefined" && _value !== null && _value.length > 0;
}

const getLeaveRequestStatus = (_value) =>
{
    if(itemListExists(_value))
    {
        if(_value.toLowerCase() === "pending")
            return "blue white-text padding-left-half align-center"

        if(_value.toLowerCase() === "approved")
            return "green white-text padding-left-half align-center"

        if(_value.toLowerCase() === "rejected")
            return "red white-text padding-left-half align-center"
    }
    return ""
}

export {
    isValidDate, isValidDateFormat, isNumeric, formatFileSize, itemExists, itemListExists,
    getLeaveRequestStatus
}