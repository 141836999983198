<template>
  <div id="loading" class="loading-overlay" v-show="isProcessing">
    <div class="lds-ellipsis centered front">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>
<style>
.loading-overlay {
  background-color: #fff;
  z-index: 5000;
  position: fixed;
  width: 100%;
  height: 100%;
  opacity: 0.5;
}
</style>
<script>
import { mapState } from "vuex";
export default {
  name: "LoadingComponent",
  data() {
    return {};
  },

  mounted() {

  },
  created() {

  },
  computed: {
    ...mapState(["processing"]),
      isProcessing()
      {
          return (typeof this.processing !== "undefined"&& this.processing !== null && this.processing > 0);
      }
  }
};
</script>
