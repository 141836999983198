/**
 * Created by fulle on 2023/02/07.
 */
import http from "@/http-common";
import CUSTOM_CONSTANTS from "@/constants";
import { isNumeric } from "@/app/helpers"


export default {
  state: {
      selected: null,
      list: {
          all: null,
          paginated: null,
      },
      pagination: null,
      links: null

  },
  mutations: {
      SET_GOAL_LIST_ALL: (state, _value) => {
          state.list.all = _value;
      },
      SET_SELECTED_GOAL: (state, _value) => {
          state.selected = _value;
      },
      SET_GOAL_LIST_PAGINATED: (state, _value) => {
          state.list.paginated = _value;
      },
      UPDATE_GOAL_LIST_PAGINATED: (state, _value) => {
          Array.prototype.push.apply(state.list.paginated,_value)
          let _arr = state.list.paginated
          state.list.paginated = _arr.filter((value, index) => {
              const _value = JSON.stringify(value);
              return index === _arr.findIndex(obj => {
                      return JSON.stringify(obj) === _value;
                  });
          });
      },
      SET_GOAL_PAGINATION: (state, _value) => {
          state.pagination = _value;
      },
      SET_GOAL_PAGINATION_LINKS: (state, _value) => {
          state.links = _value;
      },
  },
  actions: {
      COMMIT_SET_SELECTED_GOAL: ({ commit}, _value) => {
          commit("SET_SELECTED_GOAL", _value);
      },
      COMMIT_SET_GOAL_LIST_PAGINATED: ({ commit}, _value) => {
          commit("SET_GOAL_LIST_PAGINATED", _value);
      },
      REQUEST_GOAL_LIST_PAGINATED: ({ commit,dispatch }, _formData) => {
          commit("START_PROCESSING", { root: true });
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          return new Promise(resolve => {
              http({
                  url: '/goal/all',
                  method: "POST",
                  timeout: 30000,
                  data: _formData
              })
                  .then(_response => {
                      window.console.log("Response GOAL PAGINATED LIST: ", _response);
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      let _page = 1
                      if(typeof _formData !== "undefined" && _formData !== null)
                      {
                          if(typeof _formData.page !== "undefined" && _formData.page !== null && isNumeric(_formData.page)&&parseInt(_formData.page)>0)
                              _page = parseInt(_formData.page)
                      }
                      if(_page <= 1)
                          commit('SET_GOAL_LIST_PAGINATED', _response.data.data);
                      else
                          commit('UPDATE_GOAL_LIST_PAGINATED', _response.data.data);
                      commit('SET_GOAL_PAGINATION', _response.data.pagination);
                      commit('SET_GOAL_PAGINATION_LINKS', _response.data.links);
                      commit("STOP_PROCESSING", { root: true });
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_GOAL_LIST_SELECT: ({ commit,dispatch }, _formData) => {
          commit("START_PROCESSING", { root: true });
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          return new Promise(resolve => {
              http({
                  url: '/goal/select',
                  method: "POST",
                  timeout: 30000,
                  data: _formData
              })
                  .then(_response => {
                      window.console.log("Response GOAL PAGINATED LIST: ", _response);
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      commit('SET_GOAL_LIST_ALL', _response.data.data);
                      commit("STOP_PROCESSING", { root: true });
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_GOAL_SAVE: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/goal/save',
                  method: "POST",
                  timeout: 30000,
                  data: _formData,
              })
                  .then(_response => {
                      window.console.log("Response GOAL STORE: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_GOAL_SCORE: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/goal/score',
                  method: "POST",
                  timeout: 30000,
                  data: _formData,
              })
                  .then(_response => {
                      window.console.log("Response GOAL SCORE: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      commit('SET_SELECTED_GOAL', _response.data.data);
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_GOAL_COMPLETE: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/goal/complete',
                  method: "POST",
                  timeout: 30000,
                  data: _formData,
              })
                  .then(_response => {
                      window.console.log("Response GOAL COMPLETE: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      commit('SET_SELECTED_GOAL', _response.data.data)
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_GOAL_VIEW: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/goal/view',
                  method: "POST",
                  timeout: 30000,
                  data: _formData
              })
                  .then(_response => {
                      window.console.log("Response GOAL VIEW: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      commit("SET_SELECTED_GOAL", _response.data.data)
                      resolve(_response.data.data);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_GOAL_ACTIVATE: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/goal/activate',
                  method: "POST",
                  timeout: 30000,
                  data: _formData
              })
                  .then(_response => {
                      window.console.log("Response GOAL VIEW: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      commit("SET_SELECTED_GOAL", _response.data.data)
                      resolve(_response.data.data);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_GOAL_DEACTIVATE: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/goal/deactivate',
                  method: "POST",
                  timeout: 30000,
                  data: _formData
              })
                  .then(_response => {
                      window.console.log("Response GOAL VIEW: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      commit("SET_SELECTED_GOAL", _response.data.data)
                      resolve(_response.data.data);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },


  },
};
