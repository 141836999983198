<!-- 
	This is the default layout, used in sign-in and sign-up pages.
 -->

<template>
	<div>

		<!-- Default Layout -->
		<a-layout class="layout-default" id="layout-default" :class="[layoutClass]">

			<!-- Layout Header ( Navbar ) -->
			<DefaultHeader v-if="isAuthenticated"></DefaultHeader>
			<!-- / Layout Header ( Navbar ) -->


			<!-- Page Content -->
			<a-layout-content>
				<router-view />
			</a-layout-content>
			<!-- / Page Content -->

			<!-- Layout Footer -->
			<DefaultFooter v-if="! $route.meta.nofooter"></DefaultFooter>
			<!-- / Layout Footer -->

		</a-layout>
		<!-- / Default Layout -->

	</div>
</template>

<script>

    import { mapState } from 'vuex'
	/*import DefaultHeader from '../components/Headers/DefaultHeader' ;*/
    import DefaultHeader from '@/app/components/ant-design/headers/DefaultHeader' ;
	import DefaultFooter from '../components/Footers/DefaultFooter' ;


	export default ({
		components: {
			DefaultHeader,
			DefaultFooter,
		},
		data() {
			return {
			}
		},
		computed: {
			// Sets layout's element's class based on route's meta data.
			layoutClass() {
				return this.$route.meta.layoutClass ;
			},
            ...mapState({
                selected_organisation: state => state.organisation.selected,
                auth_user: state => state.auth.user,
                auth_selected_role: state => state.auth.selected_role,
                auth_roles: state => state.auth.roles,
            }),
            hasAuthUser()
            {
                return this.$itemExists(this.auth_user)
            },
            hasSelectedOrganisation()
            {
                return this.$itemExists(this.selected_organisation)
            },
            hasAuthRoles()
            {
                return this.hasAuthUser && this.$itemListExists(this.auth_roles)
            },
            isAuthenticated()
            {
                if(this.hasAuthRoles && this.$itemExists(this.auth_selected_role))
                {
                    let _thisObj = this
                    let _arr_filter = this.auth_roles.filter((_item) => {
                        return _thisObj.auth_selected_role.alias === _item.alias
                    })
                    if(_thisObj.$itemListExists(_arr_filter))
                        return true
                }

                return false
            },
            currentRole() {
                if(this.isAuthenticated)
                    return this.auth_selected_role

                return null
            },
            hasCurrentRole()
            {
                return this.$itemExists(this.currentRole)
            },
            canActAsSystemAdmin()
            {
                return this.hasCurrentRole && ["system-admin", "super-admin"].indexOf(this.currentRole.alias) >= 0
            },
            canActAsSupermAdmin()
            {
                return this.hasCurrentRole && ["super-admin"].indexOf(this.currentRole.alias) >= 0
            },
		},
	})

</script>