// VueJS
import Vue from 'vue'

// Ant Design Vue
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import moment from "moment";
import Vuelidate from "vuelidate";

Vue.use(Antd);

// Photoswipe Gallery
import Photoswipe from 'vue-pswipe'
Vue.use(Photoswipe)

// Template Layouts
import DefaultLayout from './layouts/Default.vue'
import DashboardLayout from './layouts/Dashboard.vue'
import DashboardRTLLayout from './layouts/DashboardRTL.vue'

// Adding template layouts to the vue components.
Vue.component("layout-default", DefaultLayout);
Vue.component("layout-dashboard", DashboardLayout);
Vue.component("layout-dashboard-rtl", DashboardRTLLayout);

// Main application view
import App from './App.vue'

// Vue Router
import router from './router'
import store from "./store"
import http from "./http-common"

// App Styling
import './scss/app.scss';

import { formatFileSize } from "@/app/helpers"


Vue.config.productionTip = false;
Vue.prototype.moment = moment;
Vue.use(Vuelidate);

const token = localStorage.getItem("token");
if (typeof token !== "undefined" && token !== null) {
    http.defaults.headers.common["Authorization"] = "Bearer " + token;
}

/*Vue.prototype.$setPageHeader = function (_content) {
 this.$store.dispatch("COMMIT_SET_PAGE_HEADER_HTML", _content);
 };
 Vue.prototype.$clearPageHeader = function () {
 this.$store.dispatch("COMMIT_SET_PAGE_HEADER_HTML", null);
 };*/

String.prototype.startsWith ||
(String.prototype.startsWith = function (word) {
    return this.lastIndexOf(word, 0) === 0;
});
String.prototype.includes ||
(String.prototype.includes = function (word) {
    return this.indexOf(word) >= 0;
});
String.prototype.capitalize = function () {
    return this.charAt(0).toUpperCase() + this.slice(1);
};

String.prototype.isValidateEmail = function () {
    return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
        this
    );
};
String.prototype.isValidPasswordFormat = function () {
    return /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
        this
    );
};
function isValidPhone(_value)
{
    if(typeof _value !== "string")
        return false;
    return /^[+1-9][0-9]{6,14}$/.test(_value.replace(/\)|\(| /gi, ""));
}
String.prototype.isValidPhone = function() {
    return isValidPhone(this);
};
String.prototype.isNumeric = function () {
    return isNumeric(this);
};
function isNumeric(_value) {
    return !isNaN(parseFloat(_value)) && isFinite(_value);
}

Vue.prototype.$isNumeric = (_value) => {
    return isNumeric(_value);
};
Vue.prototype.$formatFileSize = (bytes, decimalPoints) => {
    return formatFileSize(bytes, decimalPoints)
}

Vue.prototype.$randomNumber = (min, max) => {
    return Math.random() * (max - min) + min;
};
Vue.prototype.$textExists = (_value) => {
    return typeof _value !== "undefined" && _value !== null && _value.length > 0;
};
Vue.prototype.$itemExists = (_value) => {
    return typeof _value !== "undefined" && _value !== null;
};
Vue.prototype.$itemListExists = (_value) => {
    return typeof _value !== "undefined" && _value !== null && _value.length > 0;
};
Vue.prototype.$isScrolledIntoView = (el) => {
    let rect = el.getBoundingClientRect()
    let elemTop = rect.top
    let elemBottom = rect.bottom

    let isVisible = elemTop < window.innerHeight && elemBottom >= 0
    return isVisible
};
Vue.prototype.$diffInYears = (birthday, _age_limit) => {
    let _now = Date.now()
    if(typeof _age_limit === "undefined" || _age_limit === null || !isNumeric(_age_limit) || (_age_limit < (_now.getUTCFullYear() - 90)) || (_age_limit >_now.getUTCFullYear()))
        _age_limit = 1970

    let ageDifMs = _now - birthday;
    let ageDate = new Date(ageDifMs); // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - _age_limit);

};


String.prototype.endsWith ||
(String.prototype.endsWith = function (word) {
    return this.indexOf(word, this.length - word.length) !== -1;
});

String.prototype.isNumber = function () {
    return /^[0-9]+$/.test(this);
};

Date.prototype.addHours = function (h) {
    this.setTime(this.getTime() + h * 60 * 60 * 1000);
    return this;
};
Date.prototype.addDays = function (d) {
    this.setTime(this.getTime() + d * 24 * 60 * 60 * 1000);
    return this;
};

Date.daysBetween = function (date1, date2) {
    //Get 1 day in milliseconds
    //var one_day=1000*60*60*24;

    // Convert both dates to milliseconds
    var date1_ms = date1.getTime();
    var date2_ms = date2.getTime();

    // Calculate the difference in milliseconds
    var difference_ms = date2_ms - date1_ms;
    //take out milliseconds
    difference_ms = difference_ms / 1000;
    var seconds = Math.floor(difference_ms % 60);
    difference_ms = difference_ms / 60;
    var minutes = Math.floor(difference_ms % 60);
    difference_ms = difference_ms / 60;
    var hours = Math.floor(difference_ms % 24);
    var days = Math.floor(difference_ms / 24);

    return (
        days +
        " days, " +
        hours +
        " hours, " +
        minutes +
        " minutes, and " +
        seconds +
        " seconds"
    );
};

const GlobalHelperPlugin = {
    install(Vue) {
        (Vue.prototype.getStatusClass = function (_item) {
            if (
                typeof _item !== "undefined" &&
                _item !== null &&
                typeof _item.active !== "undefined" &&
                _item.active !== null
            ) {
                return (
                    "material-icons tiny " +
                    (_item.active === true ? "green-text darken-2" : "red-text darken-2")
                );
            }
            return null;
        }),
            (Vue.prototype.getStatusTextClass = function (_value) {
                if (
                    _value !== null
                ) {
                    return (
                        "material-icons tiny " +
                        (_value === true ? "green-text darken-2" : "red-text darken-2")
                    );
                }
                return null;
            }),
            (Vue.prototype.getActivationMessage = function (_item) {
                if (
                    typeof _item !== "undefined" &&
                    _item !== null &&
                    typeof _item.active !== "undefined" &&
                    _item.active !== null
                ) {
                    if (_item.active === true) {
                        return "Deactivate " + _item.name;
                    } else {
                        return "Activate " + _item.name;
                    }
                }
                return null;
            }),
            (Vue.prototype.getStatusMessage = function (_item) {
                if (
                    typeof _item !== "undefined" &&
                    _item !== null &&
                    typeof _item.active !== "undefined" &&
                    _item.active !== null
                ) {
                    if (_item.active === true) {
                        return "Active";
                    } else {
                        return "Inactivate";
                    }
                }
                return null;
            }),
            (Vue.prototype.isItemActive = function (_item) {
                return (
                    typeof _item !== "undefined" &&
                    _item !== null &&
                    typeof _item.active !== "undefined" &&
                    _item.active !== null &&
                    _item.active === true
                );
            }),
            (Vue.prototype.isNumeric = function (_item) {
                return isNumeric(_item);
            });
    },
};
Vue.use(GlobalHelperPlugin);

export const bus = new Vue();
/*window.console.log("YAH")
import i18n from "./i18n"*/

// Initialize Vue
new Vue({
  router,
    store,
    token:token,
    data: {},
    /*i18n,*/
    render: h => h(App)
}).$mount('#app')