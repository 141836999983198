/**
 * Created by fulle on 2023/02/07.
 */
import http from "@/http-common";
import { isNumeric } from "@/app/helpers"
import CUSTOM_CONSTANTS from "@/constants";


export default {
  state: {
      selected: null,
      list: {
          all: null,
          paginated: null
      },
      pagination: null,
      links: null

  },
  mutations: {
      SET_LIST_ALL_USER: (state, _value) => {
          state.list.all = _value;
      },
      SET_SELECTED_USER: (state, _value) => {
          state.selected = _value;
      },
      SET_USER_LIST_PAGINATED: (state, _value) => {
          state.list.paginated = _value;
      },
      UPDATE_USER_LIST_PAGINATED: (state, _value) => {
          Array.prototype.push.apply(state.list.paginated,_value)
          let _arr = state.list.paginated
          state.list.paginated = _arr.filter((value, index) => {
              const _value = JSON.stringify(value);
              return index === _arr.findIndex(obj => {
                      return JSON.stringify(obj) === _value;
                  });
          });
      },
      SET_USER_PAGINATION: (state, _value) => {
          state.pagination = _value;
      },
      SET_USER_PAGINATION_LINKS: (state, _value) => {
          state.links = _value;
      },
  },
  actions: {
      COMMIT_SET_SELECTED_USER: ({ commit}, _value) => {
          commit("SET_SELECTED_USER", _value);
      },
      COMMIT_SET_USER_LIST_PAGINATED: ({ commit}, _value) => {
          commit("SET_USER_LIST_PAGINATED", _value);
      },
      REQUEST_USER_LIST_PAGINATED: ({ commit,dispatch }, _formData) => {
          commit("START_PROCESSING", { root: true });
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          return new Promise(resolve => {
              http({
                  url: '/user/list',
                  method: "POST",
                  timeout: 30000,
                  data: _formData
              })
                  .then(_response => {
                      window.console.log("Response user PAGINATED LIST: ", _response);
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      let _page = 1
                      if(typeof _formData !== "undefined" && _formData !== null)
                      {
                          if(typeof _formData.page !== "undefined" && _formData.page !== null && isNumeric(_formData.page)&&parseInt(_formData.page)>0)
                              _page = parseInt(_formData.page)
                      }
                      if(_page <= 1)
                        commit('SET_USER_LIST_PAGINATED', _response.data.data);
                      else
                          commit('UPDATE_USER_LIST_PAGINATED', _response.data.data);
                      commit('SET_USER_PAGINATION', _response.data.pagination);
                      commit('SET_USER_PAGINATION_LINKS', _response.data.links);
                      commit("STOP_PROCESSING", { root: true });
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_USER_VIEW: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/user/view',
                  method: "POST",
                  timeout: 30000,
                  data: _formData
              })
                  .then(_response => {
                      window.console.log("Response user VIEW: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      commit("SET_SELECTED_USER", _response.data.data)
                      resolve(_response.data.data);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_USER_SAVE: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/user/save',
                  method: "POST",
                  timeout: 30000,
                  data: _formData,
              })
                  .then(_response => {
                      window.console.log("Response USER STORE: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_USER_VERIFY_EMAIL: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/user/email/verify',
                  method: "POST",
                  timeout: 30000,
                  data: _formData,
              })
                  .then(_response => {
                      window.console.log("Response USER STORE: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_USER_PASSWORD_RESET_CODE: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/user/password/code',
                  method: "POST",
                  timeout: 30000,
                  data: _formData,
              })
                  .then(_response => {
                      window.console.log("Response USER STORE: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_USER_UNLINK_ORGANISATION: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/user/organisation/remove',
                  method: "POST",
                  timeout: 30000,
                  data: _formData,
              })
                  .then(_response => {
                      window.console.log("Response USER Organisation Remove: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
  },
};
