/**
 * Created by fulle on 2023/02/07.
 */
import http from "@/http-common";
import CUSTOM_CONSTANTS from "@/constants";


export default {
  state: {
      selected: null,
      list: {
          all: null,
          paginated: null
      },
      pagination: null,
      links: null

  },
  mutations: {
      SET_LIST_ALL: (state, _value) => {
          state.list.all = _value;
      },
      SET_SELECTED_CERTIFICATE: (state, _value) => {
          state.selected = _value;
      },
      SET_LIST_PAGINATED: (state, _value) => {
          state.list.paginated = _value;
      },
      SET_ORGANISATION_PAGINATION: (state, _value) => {
          state.pagination = _value;
      },
      SET_ORGANISATION_PAGINATION_LINKS: (state, _value) => {
          state.links = _value;
      },
  },
  actions: {
      COMMIT_SET_SELECTED_CERTIFICATE: ({ commit}, _value) => {
          commit("SET_SELECTED_CERTIFICATE", _value);
      },
      REQUEST_CERTIFICATE_LIST_SELECT: ({ commit,dispatch }, _formData) => {
          commit("START_PROCESSING", { root: true });
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          return new Promise(resolve => {
              http({
                  url: '/certificate/select',
                  method: "POST",
                  timeout: 30000,
                  data: _formData
              })
                  .then(_response => {
                      window.console.log("Response CERTIFICATE PAGINATED LIST: ", _response);
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      commit('SET_LIST_ALL', _response.data.data);
                      commit("STOP_PROCESSING", { root: true });
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_CERTIFICATE_SAVE: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/certificate/save',
                  method: "POST",
                  timeout: 30000,
                  data: _formData,
              })
                  .then(_response => {
                      window.console.log("Response CERTIFICATE STORE: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_CERTIFICATE_VIEW: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/certificate/view',
                  method: "POST",
                  timeout: 30000,
                  data: _formData
              })
                  .then(_response => {
                      window.console.log("Response CERTIFICATE VIEW: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      commit("SET_SELECTED_CERTIFICATE", _response.data.data)
                      resolve(_response.data.data);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_CERTIFICATE_UPDATE_LOGO: ({ commit, dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });

          return new Promise((resolve) => {
              http({
                  url: '/certificate/upload',
                  method: "POST",
                  timeout: 30000,
                  data: _formData,
                  headers: {
                      'Content-Type': 'multipart/form-data',
                      'enctype': 'multipart/form-data',
                  },
              })
                  .then((_response) => {
                      window.console.log("Response CERTIFICATE UPLOAD LOGO: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      commit("SET_SELECTED_CERTIFICATE", _response.data.data)
                      resolve(_response.data.data);
                  })
                  .catch((_response) => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },

  },
};
