/**
 * Created by fulle on 2023/02/07.
 */
import http from "@/http-common";
import CUSTOM_CONSTANTS from "@/constants";
import { isNumeric } from "@/app/helpers"


export default {
  state: {
      selected: null,
      list: {
          all: null,
          paginated: null,
          countries: null,
          country_codes: null
      },
      pagination: null,
      links: null

  },
  mutations: {
      SET_LIST_ALL_ORGANISATION: (state, _value) => {
          state.list.all = _value;
      },
      SET_LIST_COUNTRIES: (state, _value) => {
          state.list.countries = _value;
          state.list.country_codes = []
          if(typeof state.list.countries !== "undefined" && state.list.countries !== null && state.list.countries.length > 0)
          {
              for(let i=0;i<state.list.countries.length;i++)
                  state.list.country_codes.push(state.list.countries[i].key)
          }
          let _sessionStorage = null;
          if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
              _sessionStorage = window.sessionStorage;
          else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
              _sessionStorage = sessionStorage;
          if(_sessionStorage !== null)
          {
              _sessionStorage.setItem('country_codes', JSON.stringify(state.list.country_codes))
          }
      },
      SET_SELECTED_ORGANISATION: (state, _value) => {
          state.selected = _value;
      },
      SET_LIST_PAGINATED_ORGANISATION: (state, _value) => {
          state.list.paginated = _value;
      },


      SET_ORGANISATION_LIST_PAGINATED: (state, _value) => {
          state.list.paginated = _value;
      },
      UPDATE_ORGANISATION_LIST_PAGINATED: (state, _value) => {
          Array.prototype.push.apply(state.list.paginated,_value)
          let _arr = state.list.paginated
          state.list.paginated = _arr.filter((value, index) => {
              const _value = JSON.stringify(value);
              return index === _arr.findIndex(obj => {
                      return JSON.stringify(obj) === _value;
                  });
          });
      },
      SET_ORGANISATION_PAGINATION: (state, _value) => {
          state.pagination = _value;
      },
      SET_ORGANISATION_PAGINATION_LINKS: (state, _value) => {
          state.links = _value;
      },
  },
  actions: {
      COMMIT_SET_SELECTED_ORGANISATION: ({ commit}, _value) => {
          commit("SET_SELECTED_ORGANISATION", _value);
      },
      COMMIT_SET_ORGANISATION_LIST_PAGINATED: ({ commit}, _value) => {
          commit("SET_ORGANISATION_LIST_PAGINATED", _value);
      },
      REQUEST_COUNTRIES_LIST_ALL: ({ commit,dispatch }, _formData) => {
          commit("START_PROCESSING", { root: true });
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          return new Promise(resolve => {
              http({
                  url: '/country/list',
                  method: "POST",
                  timeout: 30000,
                  data: _formData
              })
                  .then(_response => {
                      window.console.log("Response COUNTRY PAGINATED LIST: ", _response);
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      commit('SET_LIST_COUNTRIES', _response.data.data);
                      commit("STOP_PROCESSING", { root: true });
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_ORGANISATION_LIST_PAGINATED: ({ commit,dispatch }, _formData) => {
          commit("START_PROCESSING", { root: true });
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          return new Promise(resolve => {
              http({
                  url: '/organisation/list',
                  method: "POST",
                  timeout: 30000,
                  data: _formData
              })
                  .then(_response => {
                      window.console.log("Response ORGANISATION PAGINATED LIST: ", _response);
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      let _page = 1
                      if(typeof _formData !== "undefined" && _formData !== null)
                      {
                          if(typeof _formData.page !== "undefined" && _formData.page !== null && isNumeric(_formData.page)&&parseInt(_formData.page)>0)
                              _page = parseInt(_formData.page)
                      }
                      if(_page <= 1)
                          commit('SET_ORGANISATION_LIST_PAGINATED', _response.data.data);
                      else
                          commit('UPDATE_ORGANISATION_LIST_PAGINATED', _response.data.data);
                      commit('SET_ORGANISATION_PAGINATION', _response.data.pagination);
                      commit('SET_ORGANISATION_PAGINATION_LINKS', _response.data.links);
                      commit("STOP_PROCESSING", { root: true });
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_ORGANISATION_LIST_ACTIVE: ({ commit,dispatch }, _formData) => {
          commit("START_PROCESSING", { root: true });
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          return new Promise(resolve => {
              http({
                  url: '/organisation/list',
                  method: "POST",
                  timeout: 30000,
                  data: _formData
              })
                  .then(_response => {
                      window.console.log("Response ORGANISATION PAGINATED LIST: ", _response);
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      commit('SET_LIST_PAGINATED_ORGANISATION', _response.data.data);
                      commit('SET_ORGANISATION_PAGINATION_LINKS', _response.data.links);
                      commit('SET_ORGANISATION_PAGINATION', _response.data.pagination);
                      commit("STOP_PROCESSING", { root: true });
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_ORGANISATION_SAVE: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/organisation/save',
                  method: "POST",
                  timeout: 30000,
                  data: _formData
              })
                  .then(_response => {
                      window.console.log("Response ORGANISATION STORE: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_ORGANISATION_VIEW: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/organisation/view',
                  method: "POST",
                  timeout: 30000,
                  data: _formData
              })
                  .then(_response => {
                      window.console.log("Response ORGANISATION VIEW: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      commit("SET_SELECTED_ORGANISATION", _response.data.data)
                      let _languages = null
                      if(typeof _response.data.data.languages !== "undefined" && _response.data.data.languages !== null && typeof _response.data.data.languages.data !== "undefined" && _response.data.data.languages.data !== null)
                      {
                          _languages = _response.data.data.languages.data
                      }
                      dispatch("COMMIT_SET_LANGUAGES", _languages)
                      resolve(_response.data.data);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_ORGANISATION_UPDATE_LOGO: ({ commit, dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });

          return new Promise((resolve) => {
              http({
                  url: '/organisation/upload',
                  method: "POST",
                  timeout: 30000,
                  data: _formData,
                  headers: {
                      'Content-Type': 'multipart/form-data',
                      'enctype': 'multipart/form-data',
                  },
              })
                  .then((_response) => {
                      window.console.log("Response ORGANISATION UPLOAD LOGO: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      commit("SET_SELECTED_ORGANISATION", _response.data.data)
                      resolve(_response.data.data);
                  })
                  .catch((_response) => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_ORGANISATION_UPDATE_VAT: ({ commit, dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });

          return new Promise((resolve) => {
              http({
                  url: '/organisation/update/vat',
                  method: "POST",
                  timeout: 30000,
                  data: _formData,
              })
                  .then((_response) => {
                      window.console.log("Response ORGANISATION UPDATE VAT: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      commit("SET_SELECTED_ORGANISATION", _response.data.data)
                      resolve(_response.data.data);
                  })
                  .catch((_response) => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
  },
};
