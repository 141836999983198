/**
 * Created by fulle on 2023/02/07.
 */
import http from "@/http-common";

export default {
    state: {
        payroll_groups: null,
        selected: null,
        selected_policy: null,
        selected_refund: null,
        selected_claim: null,
        list: {
            unapproved: null,
            active: null,
            roles: null,
            deductions: null
        },
    },
    mutations: {
        SET_PAYROLL_GROUPS: (state, _value) => {
            state.payroll_groups = _value;
        },
        SET_ACCOUNT_SELECTED: (state, _value) => {
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;
            if(_sessionStorage !== null)
                _sessionStorage.setItem('selected_user', JSON.stringify(_value))
            state.selected = _value;
        },
        SET_LIST_ACTIVE: (state, _value) => {
            state.list.active = _value;
        },
        SET_LIST_ROLES: (state, _value) => {
            state.list.roles = _value;
        },
        SET_SELECTED_POLICY: (state, _value) => {
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;
            if(_sessionStorage !== null)
                _sessionStorage.setItem('selected_policy', JSON.stringify(_value))
            state.selected_policy = _value;
        },

        SET_SELECTED_CLAIM: (state, _value) => {
            state.selected_claim = _value;
        },
        SET_SELECTED_REFUND: (state, _value) => {
            state.selected_refund = _value;
        },
    },
    actions: {
        COMMIT_ACCOUNT_SET_SELECTED:({commit},_value)=>
        {
            commit('SET_ACCOUNT_SELECTED',_value);
        },
        COMMIT_ACCOUNT_SET_SELECTED_REFUND:({commit},_value)=>
        {
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;
            if(_sessionStorage !== null)
                _sessionStorage.setItem('selected_refund', JSON.stringify(_value))
            commit('SET_SELECTED_REFUND',_value);
        },
        COMMIT_ACCOUNT_SET_SELECTED_POLICY:({commit},_value)=>
        {
            commit('SET_SELECTED_POLICY',_value);
        },
        COMMIT_ACCOUNT_SET_SELECTED_CLAIM:({commit},_value)=>
        {
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;
            if(_sessionStorage !== null)
                _sessionStorage.setItem('selected_claim', JSON.stringify(_value))
            commit('SET_SELECTED_CLAIM',_value);
        },
        REQUEST_ACCOUNT_SELF_REGISTER: ({ commit, dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });
            return new Promise((resolve) => {
                http({
                    url:"/accounts/register/self",
                    method: "POST",
                    data: _formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'enctype': 'multipart/form-data',
                    },
                    timeout: 30000,
                })
                    .then((resp) => {
                        commit("STOP_PROCESSING", { root: true });
                        resolve(resp);
                    })
                    .catch((_response) => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        REQUEST_ACCOUNT_GET_OTP: ({ commit, dispatch }, _formData) => {
            http.defaults.headers.common["Content-Type"] = "application/json";
            commit("START_PROCESSING", { root: true });
            return new Promise((resolve) => {
                http({
                    url: "/auth/users/otp/send",
                    method: "POST",
                    data: _formData,
                    timeout: 30000,
                })
                    .then((resp) => {
                        commit("STOP_PROCESSING", { root: true });
                        resolve(resp);
                    })
                    .catch((_response) => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        REQUEST_ACCOUNT_VERIFY_OTP: ({ commit, dispatch }, _formData) => {
            http.defaults.headers.common["Content-Type"] = "application/json";
            commit("START_PROCESSING", { root: true });
            return new Promise((resolve) => {
                http({
                    url: "/auth/users/otp/validate",
                    method: "POST",
                    data: _formData,
                    timeout: 30000,
                })
                    .then((resp) => {
                        commit("STOP_PROCESSING", { root: true });
                        resolve(resp);
                    })
                    .catch((_response) => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        REQUEST_ACCOUNT_GET_PAYROLL_GROUPS: ({ commit, dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });
            return new Promise((resolve) => {
                http({
                    url: "/auth/users/groups",
                    method: "GET",
                    data: _formData,
                    timeout: 30000,
                })
                    .then((resp) => {
                        commit("STOP_PROCESSING", { root: true });
                        commit("SET_PAYROLL_GROUPS", resp.data.data);
                        resolve(resp);
                    })
                    .catch((_response) => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        REQUEST_ACCOUNT_GET_ALL_ACTIVE: ({ commit, dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;

            const _user = JSON.parse(_sessionStorage.getItem('user'))

            return new Promise((resolve) => {
                http({
                    url: "/accounts/users/all",
                    method: "GET",
                    data: _formData,
                    timeout: 30000,
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-user': _user.reference,
                        'x-api-gateway': process.env.VUE_APP_HEROKU_API_KEY,
                    },
                })
                    .then((resp) => {
                        commit("STOP_PROCESSING", { root: true });
                        commit("SET_LIST_ACTIVE", resp.data.data);
                        resolve(resp);
                    })
                    .catch((_response) => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        REQUEST_ACCOUNT_GET_ALL_ROLES: ({ commit, dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;

            const _user = JSON.parse(_sessionStorage.getItem('user'))

            return new Promise((resolve) => {
                http({
                    url: "/auth/users/roles",
                    method: "GET",
                    data: _formData,
                    timeout: 30000,
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-user': _user.reference,
                        'x-api-gateway': process.env.VUE_APP_HEROKU_API_KEY,
                    },
                })
                    .then((resp) => {
                        commit("STOP_PROCESSING", { root: true });
                        commit("SET_LIST_ROLES", resp.data.data);
                        resolve(resp);
                    })
                    .catch((_response) => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        REQUEST_ACCOUNT_CREATE: ({ commit, dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;

            const _user = JSON.parse(_sessionStorage.getItem('user'))

            return new Promise((resolve) => {
                http({
                    url: "/accounts/register/mpi",
                    method: "POST",
                    data: _formData,
                    timeout: 30000,
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-user': _user.reference,
                        'x-api-gateway': process.env.VUE_APP_HEROKU_API_KEY,
                    },
                })
                    .then((resp) => {
                        commit("STOP_PROCESSING", { root: true });
                        resolve(resp);
                    })
                    .catch((_response) => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        REQUEST_ACCOUNT_DEDUCTION_RECONCILIATION: ({ commit, dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;

            const _user = JSON.parse(_sessionStorage.getItem('user'))

            return new Promise((resolve) => {
                http({
                    url: "/ministry/report",
                    method: "POST",
                    data: _formData,
                    timeout: 30000,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'enctype': 'multipart/form-data',
                        'x-api-user': _user.reference,
                        'x-api-gateway': process.env.VUE_APP_HEROKU_API_KEY,
                    },
                })
                    .then((resp) => {
                        commit("STOP_PROCESSING", { root: true });
                        resolve(resp);
                    })
                    .catch((_response) => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        REQUEST_ACCOUNT_DOWNLOAD_DEDUCTIONS: ({ commit, dispatch }) => {
            commit("START_PROCESSING", { root: true });
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;

            const _user = JSON.parse(_sessionStorage.getItem('user'))

            return new Promise((resolve) => {
                http({
                    url: "/ministry/report",
                    method: "GET",
                    timeout: 30000,
                    headers: {
                        'x-api-user': _user.reference,
                        'x-api-gateway': process.env.VUE_APP_HEROKU_API_KEY,
                    },
                })
                    .then((resp) => {
                        commit("STOP_PROCESSING", { root: true });
                        if(typeof resp.data.success !== "undefined" && resp.data.success !== null && resp.data.success === true)
                            resolve(resp.data);
                        else
                            reject(resp)
                    })
                    .catch((_response) => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        REQUEST_ACCOUNT_POLICY_REFUND: ({ commit, dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;

            const _user = JSON.parse(_sessionStorage.getItem('user'))

            return new Promise((resolve) => {
                http({
                    url: "/policy/refund/request",
                    method: "POST",
                    timeout: 30000,
                    data: _formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'enctype': 'multipart/form-data',
                        'x-api-user': _user.reference,
                        'x-api-gateway': process.env.VUE_APP_HEROKU_API_KEY,
                    },
                })
                    .then((resp) => {
                        commit("STOP_PROCESSING", { root: true });
                        if(typeof resp.data.success !== "undefined" && resp.data.success !== null && resp.data.success === true)
                            resolve(resp.data);
                        else
                            reject(resp)
                    })
                    .catch((_response) => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        REQUEST_ACCOUNT_POLICY_REFUND_REJECT: ({ commit, dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;

            const _user = JSON.parse(_sessionStorage.getItem('user'))

            return new Promise((resolve) => {
                http({
                    url: "/policy/refund/reject",
                    method: "POST",
                    timeout: 30000,
                    data: _formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'enctype': 'multipart/form-data',
                        'x-api-user': _user.reference,
                        'x-api-gateway': process.env.VUE_APP_HEROKU_API_KEY,
                    },
                })
                    .then((resp) => {
                        commit("STOP_PROCESSING", { root: true });
                        if(typeof resp.data.success !== "undefined" && resp.data.success !== null && resp.data.success === true)
                            resolve(resp.data);
                        else
                            reject(resp)
                    })
                    .catch((_response) => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        REQUEST_ACCOUNT_POLICY_REFUND_VERIFY: ({ commit, dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;

            const _user = JSON.parse(_sessionStorage.getItem('user'))

            return new Promise((resolve) => {
                http({
                    url: "/policy/refund/verify",
                    method: "POST",
                    timeout: 30000,
                    data: _formData,
                    headers: {
                        'x-api-user': _user.reference,
                        'x-api-gateway': process.env.VUE_APP_HEROKU_API_KEY,
                    },
                })
                    .then((resp) => {
                        commit("STOP_PROCESSING", { root: true });
                        if(typeof resp.data.success !== "undefined" && resp.data.success !== null && resp.data.success === true)
                            resolve(resp.data);
                        else
                            reject(resp)
                    })
                    .catch((_response) => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        REQUEST_ACCOUNT_POLICY_REFUND_APPROVE: ({ commit, dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;

            const _user = JSON.parse(_sessionStorage.getItem('user'))

            return new Promise((resolve) => {
                http({
                    url: "/policy/refund/complete",
                    method: "POST",
                    timeout: 30000,
                    data: _formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'enctype': 'multipart/form-data',
                        'x-api-user': _user.reference,
                        'x-api-gateway': process.env.VUE_APP_HEROKU_API_KEY,
                    },
                })
                    .then((resp) => {
                        commit("STOP_PROCESSING", { root: true });
                        if(typeof resp.data.success !== "undefined" && resp.data.success !== null && resp.data.success === true)
                            resolve(resp.data);
                        else
                            reject(resp)
                    })
                    .catch((_response) => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        REQUEST_ACCOUNT_POLICY_CLAIM: ({ commit, dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;

            const _user = JSON.parse(_sessionStorage.getItem('user'))

            return new Promise((resolve) => {
                http({
                    url: "/policy/claim/request",
                    method: "POST",
                    timeout: 30000,
                    data: _formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'enctype': 'multipart/form-data',
                        'x-api-user': _user.reference,
                        'x-api-gateway': process.env.VUE_APP_HEROKU_API_KEY,
                    },
                })
                    .then((resp) => {
                        commit("STOP_PROCESSING", { root: true });
                        if(typeof resp.data.success !== "undefined" && resp.data.success !== null && resp.data.success === true)
                            resolve(resp.data);
                        else
                            reject(resp)
                    })
                    .catch((_response) => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        REQUEST_ACCOUNT_POLICY_CLAIM_REJECT: ({ commit, dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;

            const _user = JSON.parse(_sessionStorage.getItem('user'))

            return new Promise((resolve) => {
                http({
                    url: "/policy/claim/reject",
                    method: "POST",
                    timeout: 30000,
                    data: _formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'enctype': 'multipart/form-data',
                        'x-api-user': _user.reference,
                        'x-api-gateway': process.env.VUE_APP_HEROKU_API_KEY,
                    },
                })
                    .then((resp) => {
                        commit("STOP_PROCESSING", { root: true });
                        if(typeof resp.data.success !== "undefined" && resp.data.success !== null && resp.data.success === true)
                            resolve(resp.data);
                        else
                            reject(resp)
                    })
                    .catch((_response) => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        REQUEST_ACCOUNT_POLICY_CLAIM_VERIFY: ({ commit, dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;

            const _user = JSON.parse(_sessionStorage.getItem('user'))

            return new Promise((resolve) => {
                http({
                    url: "/policy/claim/verify",
                    method: "POST",
                    timeout: 30000,
                    data: _formData,
                    headers: {
                        'x-api-user': _user.reference,
                        'x-api-gateway': process.env.VUE_APP_HEROKU_API_KEY,
                    },
                })
                    .then((resp) => {
                        commit("STOP_PROCESSING", { root: true });
                        if(typeof resp.data.success !== "undefined" && resp.data.success !== null && resp.data.success === true)
                            resolve(resp.data);
                        else
                            reject(resp)
                    })
                    .catch((_response) => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        REQUEST_ACCOUNT_POLICY_CLAIM_APPROVE: ({ commit, dispatch }, _formData) => {
            commit("START_PROCESSING", { root: true });
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;

            const _user = JSON.parse(_sessionStorage.getItem('user'))

            return new Promise((resolve) => {
                http({
                    url: "/policy/claim/complete",
                    method: "POST",
                    timeout: 30000,
                    data: _formData,
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        'enctype': 'multipart/form-data',
                        'x-api-user': _user.reference,
                        'x-api-gateway': process.env.VUE_APP_HEROKU_API_KEY,
                    },
                })
                    .then((resp) => {
                        commit("STOP_PROCESSING", { root: true });
                        if(typeof resp.data.success !== "undefined" && resp.data.success !== null && resp.data.success === true)
                            resolve(resp.data);
                        else
                            reject(resp)
                    })
                    .catch((_response) => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
        REQUEST_ACCOUNT_GET_POLICY: ({ commit, dispatch }, _id) => {
            commit("START_PROCESSING", { root: true });
            let _sessionStorage = null;
            if(typeof window.sessionStorage !== "undefined" && window.sessionStorage !== null)
                _sessionStorage = window.sessionStorage;
            else if(typeof sessionStorage !== "undefined" && sessionStorage !== null)
                _sessionStorage = sessionStorage;

            const _user = JSON.parse(_sessionStorage.getItem('user'))

            return new Promise((resolve) => {
                http({
                    url: "/policy/"+_id,
                    method: "GET",
                    timeout: 30000,
                    headers: {
                        'Content-Type': 'application/json',
                        'x-api-user': _user.reference,
                        'x-api-gateway': process.env.VUE_APP_HEROKU_API_KEY,
                    },
                })
                    .then((resp) => {
                        commit("STOP_PROCESSING", { root: true });
                        window.console.log("policy", resp)
                        if(typeof resp.data.success !== "undefined" && resp.data.success !== null && resp.data.success === true)
                        {
                            commit("SET_SELECTED_POLICY", resp.data.data);
                            resolve(resp.data);
                        }
                        else
                            reject(resp)
                    })
                    .catch((_response) => {
                        commit("STOP_PROCESSING", { root: true });
                        dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                    });
            });
        },
    },
};
