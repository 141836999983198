<!-- 
	This is the main page of the application, the layout component is used here,
	and the router-view is passed to it.
	Layout component is dynamically declared based on the layout for each route,
	specified in routes list router/index.js .
 -->

<template>
	<a-config-provider :locale="locale">
	<div id="app">
		<div v-if="$itemExists(locale)" class="change-locale margin-left-half">
			<a-radio-group v-model:value="lang" size="small">
				<a-radio-button v-for="(_item, _index) in list_languages" :key="_item.code" :checked="locale.locale === _item.code" value="en">{{ _item.name }}</a-radio-button>
			</a-radio-group>
		</div>
		<loading-component></loading-component>
		<confirm-modal-component :actionName="actionName"></confirm-modal-component>
		<message-modal-component :messages="messages"></message-modal-component>
		<component :is="layout">
			<router-view />
		</component>

	</div>
	</a-config-provider>
</template>

<style>
	/*@import "assets/appsheet.css";
	@import "assets/password-meter.css";
	*/
	@import "assets/margin.css";
	@import "assets/style.css";
	@import "assets/padding.css";
	@import "assets/borders.css";
	@import "assets/loader.css";
	@import "assets/vert-offset.css";
	@import "assets/colors.css";
	@import "assets/text.css";
	/*@import "assets/js/cycle.js";*/
</style>

<script>

    import http from "./http-common"
    import {mapState} from "vuex"
    import { bus } from "@/main"

    import enUS from 'ant-design-vue/es/locale/en_US'
    import frFR from 'ant-design-vue/es/locale/fr_FR'

    import ConfirmModalComponent from "./app/components/ant-design/ConfirmModalComponent"
    import MessageModalComponent from "./app/components/ant-design/MessageModalComponent"
    import LoadingComponent from "./app/components/ant-design/LoadingComponent"
	export default ({
        components: {
            ConfirmModalComponent,
            MessageModalComponent,
            LoadingComponent,
        },
        data() {
            return {
                lang: enUS.locale,
				default_list_languages: [
                    {
                        code: "en",
                        name: "English",
                    },
                    {
                        code: "fr",
                        name: "Français",
                    }
                ],
				list_languages: []
            }
        },
        created()
		{
		    this.list_languages = this.default_list_languages;
            this.$store.dispatch("COMMIT_REFRESH_LANGUAGES")
            this.$store.dispatch("COMMIT_REFRESH_LOCALE")
		    this.initBusListener()
			this.refreshLanguages()
		},
        mounted()
        {
            this.initHttpInterceptor();
            this.$store.dispatch("COMMIT_SESSION_REFRESH_AUTH_USER")
            this.$store.dispatch("COMMIT_SESSION_REFRESH_AUTH_ROLE")
            //this.initRouter()
            this.$store.dispatch("STOP_PROCESSING");

        },
        methods: {
            isAvailableLanguage(_value)
			{
			    if(!this.hasLanguages)
			        return true

				let _arr_found = this.languages.filter((_item) => {
			        return _item.code === _value
				})
				return this.$itemListExists(_arr_found)
			},
            initBusListener()
			{
			    let _thisObj = this
			    window.console.log("Bus Listener ON")
                bus.$on('onSystemAdminVerify', this.verifySysAdminAccess());
                bus.$on('onOrganisationAccessVerify', (_item) => {
                    window.console.log("onOrganisationAccessVerify", _item)
                    _thisObj.verifyOrganisationAccess(_item)
				})
			},
            initRouter()
            {
                let _thisObj = this
                this.$router.beforeEach((to, from, next) => {
                    _thisObj.$store.dispatch("COMMIT_SESSION_REFRESH_AUTH_USER")
                    _thisObj.$store.dispatch("COMMIT_SESSION_REFRESH_AUTH_ROLE")
                    window.console.log("Session refreshed")
                    next()
                })
            },
            initHttpInterceptor()
            {
                http.interceptors.response.use((response)=>{
							/*console.log("* Response"+JSON.stringify(response));*/
                        if(response.data.success === false || [200,201,202,204].indexOf(response.status)<0)
                        {
                            return Promise.reject(response);
                        }
                        return response
                    },
                    (error)=>{
                        window.console.log("Error* ", error);
                        let _errorMsg = "An error occurred, please contact the System administration.";
                        if(typeof error.message !== "undefined" && error.message !== null && error.message.length > 0)
                        {
                            _errorMsg = error.message;
                        }
                        else if(typeof error.errorMessage !== "undefined" && error.errorMessage !== null && error.errorMessage.length > 0)
                        {
                            _errorMsg = error.errorMessage;
                        }
                        else if(error.status === 400 && typeof error.code !== "undefined" && error.code !== null && error.code.length > 0 && typeof error.name !== "undefined" && error.name !== null && error.name === "FirebaseError")
                        {
                            _errorMsg = "Firebase Error";
                            if(error.code === ["auth/user-not-found", "auth/user-not-found"].indexOf(error.code)>=0)
                            {
                                _errorMsg = "Invalid Email Address";
                            }
                            else if(error.code === ["auth/expired-action-code"].indexOf(error.code)>=0)
                            {
                                _errorMsg = "Your Reset Code has expired, please request for a new one.";
                            }
                            else if(error.code === ["auth/invalid-action-code"].indexOf(error.code)>=0)
                            {
                                _errorMsg = "Invalid Reset Code.";
                            }
                            else if(error.code === ["auth/user-disabled"].indexOf(error.code)>=0)
                            {
                                _errorMsg = "Your account has been disabled, please contact System Administration.";
                            }
                            else if(error.code === ["auth/weak-password"].indexOf(error.code)>=0)
                            {
                                _errorMsg = "Your password strength has been considered too weak, please consider using uppercase(s) (e.g. S), lowercase(s) (e.g. s) and number(s) when entering your password.";
                            }
                        }
                        let response = {
                            data: {
                                messages : [_errorMsg]
                            },
                            message: _errorMsg,
                        };

						/*console.log("* Error Response"+JSON.stringify(error));*/
                        return Promise.reject(response);
                    }
                );
            },
            verifySysAdminAccess()
			{
                this.verifyAuthentication()
			    /*if(!this.canActAsSystemAdmin)
			        this.$router.push({
						name: 'organisations'
					})*/
			},
            verifyOrganisationAccess(_organisation)
			{
			    window.console.log("check access to organisation", _organisation)
                this.verifyAuthentication()
                if(!this.canActAsSystemAdmin)
				{
				    let _thisObj = this
                    let _arr_filter = this.auth_roles.filter((_item) => {
                        return _thisObj.$itemExists(_item.organisation) &&
							_thisObj.$itemExists(_item.organisation.data) &&
							_thisObj.$itemExists(_organisation.key) &&
							_organisation.key === _item.organisation.data.key
                    })
                    if(!_thisObj.$itemListExists(_arr_filter))
					{
                        this.showError("Unauthorized")
                        this.$router.push({
                            name: 'home'
                        })
						return false
					}
					this.$store.dispatch("COMMIT_SET_AUTH_SELECTED_ROLE", _arr_filter[0])
				}
			},
			refreshLanguages()
			{
                let _thisObj = this
                if(_thisObj.$itemListExists(_thisObj.languages))
                {
                    _thisObj.list_languages = _thisObj.languages
                }
                else
                {
                    _thisObj.list_languages = _thisObj.default_list_languages
                }

                let _arr_found = _thisObj.list_languages.filter((_item) => {
                    return _item.code === _thisObj.lang
                })
                if(!_thisObj.$itemListExists(_arr_found))
                {
                    _thisObj.lang = _thisObj.list_languages[0].code
                }
			},
			verifyAuthentication()
			{
                if(!this.isAuthenticated)
                {
                    this.showError("Unauthorized")
                    this.$router.push({
                        name: 'login'
                    })
                }
			},
            showError(title,message){
                this.$notification.open({
                        message: title,
                        description:message,
                        icon: <a-icon type="sad" style="color: #CD1310" />,
            }
            );},
            showSuccess(title,message){
                this.$notification.open({
                        message: title,
                        description:message,
                        icon: <a-icon type="smile" style="color: #10CD42" />,
            }
            );
            },
        },
		computed: {
            ...mapState({
                messages: state => state.messages,
                actionName: state => state.actionName,
                page_header_html : state => state.page_header_html,
                auth_user: state => state.auth.user,
                auth_selected_role: state => state.auth.selected_role,
                auth_roles: state => state.auth.roles,
				locale: state => state.locale,
				languages: state => state.languages,
            }),
			// Sets components name based on current route's specified layout, defaults to
			// <layout-default></layout-default> component.
			layout() {
				return "layout-" + ( this.$route.meta.layout || "default" ).toLowerCase() ;
			},
            hasAuthUser()
            {
                return this.$itemExists(this.auth_user)
            },
            hasAuthRoles()
            {
                return this.hasAuthUser && this.$itemListExists(this.auth_roles)
            },
            isAuthenticated()
            {
                if(this.hasAuthRoles && this.$itemExists(this.auth_selected_role))
                {
                    let _thisObj = this
                    let _arr_filter = this.auth_roles.filter((_item) => {
                        return _thisObj.auth_selected_role.alias === _item.alias
                    })
                    if(_thisObj.$itemListExists(_arr_filter))
                        return true
                }

                return false
            },
            currentRole() {
                if(this.isAuthenticated)
                    return this.auth_selected_role

                return null
            },
            hasCurrentRole()
            {
                return this.$itemExists(this.currentRole)
            },
            canActAsSystemAdmin()
            {
                return this.hasCurrentRole && ["system-admin", "super-admin"].indexOf(this.currentRole.alias) >= 0
            },
            canActAsSupermAdmin()
            {
                return this.hasCurrentRole && ["super-admin"].indexOf(this.currentRole.alias) >= 0
            },
			hasLanguages()
			{
			    return this.$itemListExists(this.languages)
			}
		},
		watch: {
            'lang': function(_value)
			{
                if(_value === "fr")
                {
                    this.$store.dispatch("COMMIT_SET_LOCALE", frFR)
                }
                else
                {
                    this.$store.dispatch("COMMIT_SET_LOCALE", enUS)
                }
			},
            'languages': function(_value)
            {
                if(this.$itemListExists(_value))
				{
				    this.list_languages = _value
				}
				else
				{
                    this.list_languages = this.default_list_languages
				}
				let _thisObj = this
				let _arr_found = this.list_languages.filter((_item) => {
                    return _item.code === _thisObj.lang
				})
				if(!_thisObj.$itemListExists(_arr_found))
				{
				    _thisObj.lang = this.list_languages[0].code
				}
            },
		}
	})
	
</script>

<style lang="scss">
</style>