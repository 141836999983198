/**
 * Created by fulle on 2023/02/07.
 */
import http from "@/http-common";
import CUSTOM_CONSTANTS from "@/constants";
import { isNumeric } from "@/app/helpers"


export default {
  state: {
      selected: null,
      list: {
          all: null,
          paginated: null
      },
      pagination: null,
      links: null

  },
  mutations: {
      SET_SELECTED_WORKING_HOUR: (state, _value) => {
          state.selected = _value;
      },
  },
  actions: {
      COMMIT_SET_SELECTED_WORKING_HOUR: ({ commit}, _value) => {
          commit("SET_SELECTED_WORKING_HOUR", _value);
      },
      REQUEST_WORKING_HOUR_SAVE: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/organisation/working-hour/save',
                  method: "POST",
                  timeout: 30000,
                  data: _formData,
              })
                  .then(_response => {
                      window.console.log("Response WORKING_HOUR STORE: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_WORKING_HOUR_UPDATE: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/organisation/working-hour/update',
                  method: "POST",
                  timeout: 30000,
                  data: _formData,
              })
                  .then(_response => {
                      window.console.log("Response WORKING_HOUR UPDATE: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_WORKING_HOUR_ACTIVATE: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/organisation/working-hour/activate',
                  method: "POST",
                  timeout: 30000,
                  data: _formData,
              })
                  .then(_response => {
                      window.console.log("Response WORKING_HOUR UPDATE: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },
      REQUEST_WORKING_HOUR_DEACTIVATE: ({ commit,dispatch, reject }, _formData) => {
          dispatch("COMMIT_SESSION_REFRESH_AUTH", null, { root: true });
          commit("START_PROCESSING", { root: true });
          return new Promise(resolve => {
              http({
                  url: '/organisation/working-hour/deactivate',
                  method: "POST",
                  timeout: 30000,
                  data: _formData,
              })
                  .then(_response => {
                      window.console.log("Response WORKING_HOUR UPDATE: ", _response);
                      commit("STOP_PROCESSING", { root: true });
                      if(_response.data.success === false || parseInt(_response.data.error_code)>0)
                      {
                          reject(_response)
                          return false
                      }
                      resolve(_response);
                  })
                  .catch(_response => {
                      commit("STOP_PROCESSING", { root: true });
                      dispatch("RESPONSE_PROCESS_AJAX_ERROR", _response);
                  });
          });
      },

  },
};
